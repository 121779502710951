import React, { useState, useRef, useEffect } from "react";
import queryString from "query-string";
import "./App.scss";

const App: React.FC = () => {
  const [isPlaying, setIsPlaying] = useState(false);
  const [hasEntered, setHasEntered] = useState(false);
  const [isLoading, setIsLoading] = useState(true); // Estado de carga
  const audioRef = useRef<HTMLAudioElement>(new Audio("/assets/señora-arjona.mp3"));

  // Pre-cargar imágenes
  useEffect(() => {
    const preloadImages = [
      "/assets/big-40.svg",
      "/assets/kity.svg",
      "/assets/date.svg",
      "/assets/time.svg",
      "/assets/location.svg",
      "/assets/reminder.svg",
      "/assets/mute-icon.svg",
      "/assets/sound-icon.svg",
      "/assets/background.png",
    ];

    const imagePromises = preloadImages.map((src) => {
      return new Promise<void>((resolve) => {
        const img = new Image();
        img.src = src;
        img.onload = () => resolve();
      });
    });

    // Esperar a que todas las imágenes se carguen
    Promise.all(imagePromises).then(() => {
      setIsLoading(false); // Quitar pantalla de carga
    });
  }, []);

  // Detectar el final de la canción para resetear el botón
  useEffect(() => {
    const handleAudioEnd = () => setIsPlaying(false);
    const currentAudio = audioRef.current;
    currentAudio.addEventListener("ended", handleAudioEnd);
    return () => {
      currentAudio.removeEventListener("ended", handleAudioEnd);
    };
  }, []);

  // const handleWhatsAppMessage = (isGoing: boolean) => {
  //   const phone = "+5493874683870"; // Número de WhatsApp
  //   const message = isGoing
  //     ? "¡Hola! 🎁Confirmo que asistiré al cumpleaños. 🥳 Voy a llevar bebidas de primeras marcas. 🍹🍻🤣"
  //     : "¡Hola! Lamento no poder asistir al cumpleaños. 😞 Pero te haré llegar el regalo. 🎁";

  //   // URL de WhatsApp con el mensaje
  //   const url = `https://wa.me/${phone}?text=${encodeURIComponent(message)}`;
  //   //const url = `https://wa.me/${phone}?text=${message}`;
  //   window.open(url, "_blank"); // Abre el enlace en una nueva pestaña
  // };

  const handleWhatsAppMessage = (isGoing: boolean) => {
    const phone = "+5493874683870"; // Número de WhatsApp
    const message = isGoing
      ? "¡Hola Kity! Confirmo que asistiré al cumpleaños. 🥳 Voy a llevar lo que voy a tomar. 🍹🍻🤣"
      : "¡Hola Kity! Lamento no poder asistir al cumpleaños. 😞 Pero te haré llegar el regalo. 🎁🤣";

    const query = queryString.stringify({ text: message });
    const url = `https://wa.me/${phone}?${query}`;

    window.open(url, "_blank"); // Abre el enlace en una nueva pestaña
  };

  const toggleAudio = () => {
    if (!isPlaying) {
      audioRef.current.currentTime = 0; // Reinicia la canción
      audioRef.current.play().catch(() => {
        console.error("El audio no se pudo reproducir. Es posible que el navegador lo haya bloqueado.");
      });
    } else {
      audioRef.current.pause();
    }
    setIsPlaying(!isPlaying);
  };

  const handleEnter = () => {
    setHasEntered(true);
    setIsPlaying(false);
    // audioRef.current.play().catch(() => {
    //   console.error("El audio no se pudo reproducir al ingresar.");
    // });
  };

  // Mostrar pantalla de carga mientras se cargan los recursos
  if (isLoading) {
    return (
      <div className="app-container">
        <div className="background"></div>
        <div className="loading-screen" style={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'center',
          height: '100%'
        }}>
          <p style={{
            color: "#8B5A2B",
            fontSize: 20,
          }}>Cargando...</p>
        </div>
      </div>
    );
  }

  // Mostrar botón de ingreso
  if (!hasEntered) {
    return (
      <div className="app-container" style={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
      }}>
        <div className="background"></div>
        <span
          className="animate-from-bottom"
          style={{
            display: "block",
            marginBottom: 20,
            fontSize: 20,
            color: "#8B5A2B"
          }}>
          Cumple
        </span>
        <img src="/assets/kity-color.svg" alt="Reminder" className="animate-from-bottom" style={{
          width: "100px",
          marginBottom: 20
        }} />
        <button className="enter-button animate-from-bottom" onClick={handleEnter}>
          Ingresar
        </button>
      </div>
    );
  }

  // Mostrar contenido principal
  return (
    <div className="app-container">
      <div className="background"></div>

      {/* Botón de toggle para música */}
      <button className="music-toggle" onClick={toggleAudio}>
        <img
          src={isPlaying ? "/assets/mute-icon.svg" : "/assets/sound-icon.svg"}
          alt={isPlaying ? "Mute" : "Sound"}
        />
      </button>

      <div className="content">
        <img src="/assets/big-40.svg" alt="40" className="animate-from-left" style={{
          width: "250px",
          position: "absolute",
          right: 0,
          top: 40
        }} />
        <img src="/assets/kity.svg" alt="Reminder" className="animate-from-bottom" style={{
          width: "100px",
        }} />
        <img src="/assets/date.svg" alt="Date" className="animate-from-right" />
        <img src="/assets/time.svg" alt="Time" className="animate-from-top" />
        <img src="/assets/location.svg" alt="Location" className="animate-from-bottom" />

        <button
          className="location-button"
          onClick={() => {
            const googleMapsUrl = `https://www.google.com/maps/dir/?api=1&destination=-24.780822108844884,-65.46893683372859&travelmode=driving`;
            window.open(googleMapsUrl, "_blank");
          }}
        >
          Ver en Google Maps
        </button>

        <p className="question-text">¿Vas a ir?</p>

        <div className="buttons">
          <button
            className="confirm-button yes"
            onClick={() => handleWhatsAppMessage(true)}
          >
            Sí
          </button>
          <button
            className="confirm-button no"
            onClick={() => handleWhatsAppMessage(false)}
          >
            No
          </button>
        </div>

        <img
          src="/assets/reminder.svg"
          alt="Reminder"
          className="animate-from-bottom"
        />
      </div>
    </div>
  );
};

export default App;
